@tailwind base;
@tailwind components;
@tailwind utilities;

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
}

@layer utilities {
  .bg-bb-logos {
    background-image: url("/assets/bg/login-lines-hero-background.svg");
  }
}

@layer components {
  .badge {
    border-radius: theme("borderRadius.full");
    padding: theme("padding.1") theme("padding.2");
    font-size: theme("fontSize.xs");
    font-weight: 500;
  }

  .badge-success {
    background-color: theme("colors.green.50");
    color: theme("colors.teal.800");
  }

  .badge-danger {
    background-color: theme("colors.red.50");
    color: theme("colors.red.800");
  }

  .badge-warning {
    background-color: theme("colors.yellow.50");
    color: theme("colors.yellow.800");
  }

  .badge-info {
    background-color: theme("colors.blue.50");
    color: theme("colors.blue.800");
  }

  .status {
    border-radius: theme("borderRadius.full");
    padding: 0 theme("padding.2");
    font-size: theme("fontSize.xs");
    font-weight: 500;
    border: 1px solid theme("colors.gray.200");
    color: theme("colors.base-dark");
  }

  .status i {
    border-radius: theme("borderRadius.full");
    display: inline-block;
    width: 6px;
    height: 6px;
  }

  .status-active i {
    background-color: theme("colors.green.500");
  }

  .status-idle i {
    background-color: theme("colors.yellow.500");
  }

  .status-initial i {
    background-color: theme("colors.blue.400");
  }
}

// Table custom styles
.table-wrapper {
  height: calc(100vh - 220px);
  overflow: auto;
}
